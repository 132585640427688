<template>
  <v-app>
    <div>
      <!-- Filter -->
      <div class="row justify-content-end mt-n3">
        <!-- <div
          class="col-md-3"
          v-if="purpose == 'modal' && checkbox && isSortcut"
        >
          <b-input-group>
            <treeselect
              id="package"
              placeholder="Shortcut Obat"
              v-model="filter.medicine_package_id"
              :multiple="true"
              :options="medicine_packages"
              @change="packageMedicine"
            />
          </b-input-group>
        </div> -->
        <div class="col-md-4">
          <b-input-group>
            <treeselect
              v-model="filter.medicine_category_id"
              :multiple="false"
              :options="medicine_categories"
              @input="filterByMedicineCategory"
            />
          </b-input-group>
        </div>
        <div class="col-md-5">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="filter.name"
              placeholder="Saring Nama"
            ></b-form-input>
            <template #append>
              <b-button squared @click="filterName" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilter" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </div>
        
        <!-- <div class="col-md-3" v-if="purpose != 'modal'">
          <b-form-select
            id="input-ecommerce"
            v-model="filter.EcommerceFilter"
            :options="sortEcommerce"
            @change="pagination"
          >
          </b-form-select>
        </div> -->
        <div class="col-md-2">
          <b-form-select
            id="input-period"
            v-model="perPage"
            :options="formats"
            @change="pagination"
            placeholder="Per Halaman (Def. 20)"
          ></b-form-select>
        </div>
      </div>

      <!-- export -->
      <div
        class="d-flex justify-content-start align-items-center mt-5"
        v-if="purpose != 'modal'"
      >
        <button class="btn btn-primary mr-1" @click="btnExportOnClick">
          Expor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>
      <!-- end export -->
      

      <!-- Table -->
      <b-table
        :items="items"
        :fields="fields"
        responsive
        v-if="purpose != 'modal'"
      >
        
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                field.key === 'actions'
                  ? purpose == 'master'
                    ? '17%'
                    : ''
                  : '',
              width: field.key == 'generic_name' ? '15%' : '',
            }"
          />
        </template>
        <template #cell(is_ecommerce_product)="data" v-if="purpose != 'modal'">
          <template v-if="btn == true">
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 1"
              title="Aktif"
              @click="setActive(data.item.id)"
              class="mr-1 btn-light-success text-success"
              ><i class="fas fa-check px-0"></i
            ></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 0"
              title="Non Aktif"
              @click="setActive(data.item.id)"
              class="mr-1 btn-light-danger text-danger"
              ><i class="fas fa-times px-0"></i
            ></b-button>
          </template>
          <template v-if="btn == false">
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 1"
              title="Aktif"
              class="mr-1 btn-light-success text-success"
              disabled
              ><i class="fas fa-check px-0"></i
            ></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 0"
              title="Non Aktif"
              class="mr-1 btn-light-danger text-danger"
              disabled
              ><i class="fas fa-times px-0"></i
            ></b-button>
          </template>
        </template>
        <template #cell(name)="data">
          {{ data.item.name + " (" + data.item.generic_name +")" }}
        </template>
        <template #cell(medicine_remaining_stock)="data">
          {{ data.item.medicine_remaining_stock + " " + data.item.uom_name }}
        </template>
        <template #cell(sales_price)="data">
          {{ parseInt(data.item.sales_price).toLocaleString("id-ID") }}
        </template>
        <template #cell(batch)="data" v-if="purpose != 'modal'">
          <span v-html="data.item.batch"></span>
        </template>
        <template #cell(sales)="data" v-if="purpose != 'modal'">
          <span v-html="data.item.batch"></span>
        </template>
        <template #cell(actions)="data">
          <template v-if="purpose == 'master'">
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Detail"
              @click="
                $router.push({ path: '/medicine/detail/' + data.item.id })
              "
              ><i class="fas fa-eye px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Edit"
              @click="$router.push({ path: '/medicine/edit/' + data.item.id })"
              v-if="btn"
              ><i class="fas fa-edit px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="btn-danger mr-1"
              v-b-tooltip.hover
              title="Hapus"
              @click="deleteData(data.item.id)"
              v-if="btn == true && data.item.is_transaction == 0"
              ><i class="fas fa-trash px-0"></i
            ></b-button>
            <b-button
              size="sm"
              variant="primary"
              v-b-tooltip.hover
              title="Lihat Batch"
              @click="
                $router.push({ path: '/medicine/batch-stock/' + data.item.id })
              "
              ><i class="fas fa-boxes px-0"></i
            ></b-button>
          </template>

          <template v-if="purpose == 'modal' && !checkbox">
            <b-button
              size="sm"
              class="mr-1 btn-info"
              @click="
                chooseMedicine({
                  id: data.item.id,
                  name: data.item.name,
                  sales_price: data.item.sales_price,
                  stock: data.item.medicine_remaining_stock,
                  uom: data.item.uom_name
                })
              "
            >
              Pilih
            </b-button>
          </template>
          <template
            v-if="purpose == 'modal' && checkbox && !isHospitalTransaction"
          >
            <b-form-checkbox
              :checked="data.item.checked"
              :id="`checkbox-pick-item-${data.item.id}`"
              :name="`checkbox-pick-item-${data.item.id}`"
              @change="
                chooseMedicine({
                  id: data.item.id,
                  name: data.item.name,
                  sales_price: data.item.sales_price,
                  stock: data.item.medicine_remaining_stock,
                  uom: data.item.uom_name
                })
              "
            >
            </b-form-checkbox>
          </template>
          <template
            v-if="purpose == 'modal' && checkbox && isHospitalTransaction"
          >
            <b-form-checkbox
              :checked="data.item.checked"
              :disabled="data.item.stock == 0"
              :id="`checkbox-pick-item-${data.item.id}`"
              :name="`checkbox-pick-item-${data.item.id}`"
              @change="
                chooseMedicine({
                  id: data.item.id,
                  name: data.item.name,
                  sales_price: data.item.sales_price,
                  stock: data.item.medicine_remaining_stock,
                  uom: data.item.uom_name
                })
              "
            >
            </b-form-checkbox>
          </template>
        </template>
      </b-table>
      <!-- end Table -->

      <!-- table modal version -->
      <b-table
        hover
        :items="items"
        :fields="fieldPopup"
        responsive
        v-if="purpose == 'modal'"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                field.key === 'actions'
                  ? purpose == 'master'
                    ? '17%'
                    : ''
                  : '',
              width: field.key == 'generic_name' ? '15%' : '',
            }"
          />
        </template>
        <!-- <template #cell(batch)="data">
        <span v-html="data.item.batch"></span>
      </template> -->
        <template #cell(is_ecommerce_product)="data" v-if="purpose != 'modal'">
          <template v-if="btn == true">
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 1"
              title="Aktif"
              @click="setActive(data.item.id)"
              class="mr-1 btn-light-success text-success"
              ><i class="fas fa-check px-0"></i
            ></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 0"
              title="Non Aktif"
              @click="setActive(data.item.id)"
              class="mr-1 btn-light-danger text-danger"
              ><i class="fas fa-times px-0"></i
            ></b-button>
          </template>
          <template v-if="btn == false">
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 1"
              title="Aktif"
              class="mr-1 btn-light-success text-success"
              disabled
              ><i class="fas fa-check px-0"></i
            ></b-button>
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-if="data.item.is_ecommerce_product == 0"
              title="Non Aktif"
              class="mr-1 btn-light-danger text-danger"
              disabled
              ><i class="fas fa-times px-0"></i
            ></b-button>
          </template>
        </template>
        <template #cell(sales_price)="data">
          {{ parseInt(data.item.sales_price).toLocaleString("id-ID") }}
        </template>
        <template #cell(actions)="data">
          <template v-if="purpose == 'master'">
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Detail"
              @click="
                $router.push({ path: '/medicine/detail/' + data.item.id })
              "
              ><i class="fas fa-eye px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Edit"
              @click="$router.push({ path: '/medicine/edit/' + data.item.id })"
              v-if="btn"
              ><i class="fas fa-edit px-0"></i
            ></b-button>
            <b-button
              size="sm"
              class="btn-danger mr-1"
              v-b-tooltip.hover
              title="Hapus"
              @click="deleteData(data.item.id)"
              v-if="btn == true && data.item.is_transaction == 0"
              ><i class="fas fa-trash px-0"></i
            ></b-button>
            <b-button
              size="sm"
              variant="primary"
              v-b-tooltip.hover
              title="Lihat Batch"
              @click="
                $router.push({ path: '/medicine/batch-stock/' + data.item.id })
              "
              ><i class="fas fa-boxes px-0"></i
            ></b-button>
          </template>

          <template
            v-if="purpose == 'modal' && !checkbox && modalPurpose != 'takeaway'"
          >
            <b-button
              size="sm"
              class="mr-1 btn-info"
              @click="
                chooseMedicine({
                  id: data.item.id,
                  name: data.item.name,
                  sales_price: data.item.sales_price,
                  stock: data.item.medicine_remaining_stock,
                  uom: data.item.uom_name
                })
              "
            >
              Pilih
            </b-button>
          </template>
          <template
            v-if="
              purpose == 'modal' &&
              checkbox &&
              !isHospitalTransaction &&
              modalPurpose != 'takeaway'
            "
          >
            <b-form-checkbox
              :checked="data.item.checked"
              :id="`checkbox-pick-item-${data.item.id}`"
              :name="`checkbox-pick-item-${data.item.id}`"
              @change="
                chooseMedicine({
                  id: data.item.id,
                  name: data.item.name,
                  sales_price: data.item.sales_price,
                  stock: data.item.medicine_remaining_stock,
                  uom: data.item.uom_name
                })
              "
            >
            </b-form-checkbox>
          </template>
          <template
            v-if="purpose == 'modal' && checkbox && modalPurpose == 'takeaway'"
          >
            <b-form-checkbox
              :checked="data.item.checked"
              :id="`checkbox-pick-item-${data.item.id}`"
              :name="`checkbox-pick-item-${data.item.id}`"
              @change="
                chooseMedicineOrder({
                  id: data.item.id,
                  name: data.item.name,
                  sales_price: data.item.sales_price,
                  stock: data.item.medicine_remaining_stock,
                  uom: data.item.uom_name
                })
              "
            >
            </b-form-checkbox>
          </template>
          <template
            v-if="
              purpose == 'modal' &&
              checkbox &&
              isHospitalTransaction &&
              modalPurpose != 'takeaway'
            "
          >
            <b-form-checkbox
              :checked="data.item.checked"
              :disabled="data.item.stock == 0"
              :id="`checkbox-pick-item-${data.item.id}`"
              :name="`checkbox-pick-item-${data.item.id}`"
              @change="
                chooseMedicine({
                  id: data.item.id,
                  name: data.item.name,
                  sales_price: data.item.sales_price,
                  stock: data.item.medicine_remaining_stock,
                  uom: data.item.uom_name
                })
              "
            >
            </b-form-checkbox>
          </template>
        </template>
      </b-table>
      <!-- table modal version -->

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>

      <ModalMedicineReport :dataModal="items" :excel="excelData" />
    </div>
  </v-app>
</template>

<style>
.border-filter {
  border-color: #8950fc !important;
  font-size: 18px !important;
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
}

.active-abjad-filter {
  background-color: #8950fc !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 40px !important;
  padding: 0 16px;
}

.table-warning-custom{
  background-color: #f8e1b7 !important;
}
</style>

<script>
import XLSX from "xlsx";
import api from "@/core/services/api.service.js";
import module from "@/core/modules/CrudModule.js";
import ModalMedicineReport from "@/component/general/ModalMedicineReport.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import { filter } from 'vue/types/umd';

export default {
  props: {
    purpose: String,
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    modalPurpose: String,
    secondPurpose: {
      type: String,
      default: "listing",
    },
    isAbjad: Boolean,
  },
  components: {
    ModalMedicineReport,
    XLSX,
  },

  data() {
    return {
      model: null,
      formActive: {
        id: "",
      },
      resultData: "",
      loadTable: false,

      // Pagination
      perPage: 40,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,

      // Filter
      filterShow: false,
      filter: {
        name: "",
        medicine_category_id: "",
        medicine_packages_id: "",
        EcommerceFilter: "default",
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        abjad: "",
      },

      abjadActive: {
        a: "btn-info",
        b: "btn-outline-info",
        c: "btn-outline-info",
        d: "btn-outline-info",
        e: "btn-outline-info",
        f: "btn-outline-info",
        g: "btn-outline-info",
        h: "btn-outline-info",
        i: "btn-outline-info",
        j: "btn-outline-info",
        k: "btn-outline-info",
        l: "btn-outline-info",
        m: "btn-outline-info",
        n: "btn-outline-info",
        o: "btn-outline-info",
        p: "btn-outline-info",
        q: "btn-outline-info",
        r: "btn-outline-info",
        s: "btn-outline-info",
        t: "btn-outline-info",
        u: "btn-outline-info",
        v: "btn-outline-info",
        w: "btn-outline-info",
        x: "btn-outline-info",
        y: "btn-outline-info",
        z: "btn-outline-info",
      },

      abdjadCollection: [],
      noPaginations: [],

      // other
      packageReset: false,

      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          // thClass: "nowrap",
          // tdClass: "nowrap",
        },
        // {
        //   key: "generic_name",
        //   label: "Nama Umum",
        //   sortable: true,
        // },
        {
          key: "medicine_category_name",
          label: "Kategori",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "medicine_remaining_stock",
          label: "Sisa Stok",
          thClass: "nowrap",
          tdClass: "nowrap",
          sortable: true,
        },
        // {
        //   key: "minimum_stock",
        //   label: "Stok Minimum",
        //   sortable: true,
        // },
        // {
        //   key: "uom_name",
        //   label: "Satuan",
        //   sortable: true,
        // },
        {
          key: "sales_price",
          label: "Harga Jual",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        // {
        //   key: "is_ecommerce_product",
        //   label: "E-commerce",
        //   sortable: true,
        //   thClass: "nowrap",
        //   tdClass: "nowrap",
        // },
        {
          key: "batch",
          label: "Batch",
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "actions",
          label: "Aksi",
          thClass: "nowrap",
          tdClass: "nowrap",
        },
      ],
      fieldPopup: [
        {
          key: "number",
          label: "#",
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "generic_name",
          label: "Nama Umum",
          sortable: true,
        },
        {
          key: "medicine_category_name",
          label: "Kategori",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap",
        },
        {
          key: "medicine_remaining_stock",
          label: "Sisa Stok",
          sortable: true,
        },
        {
          key: "uom_name",
          label: "Satuan",
          sortable: true,
        },
        {
          key: "actions",
          label: "Aksi",
          thClass: "nowrap",
          tdClass: "nowrap",
        },
      ],

      // Table Items
      items: [],
      itemProps: [],
      medicine_categories: [],
      medicine_packages: [],
      total_medicine: "",
      default_total_medicine: "",
      medicineItem: [],
      excelData: [],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
      sortEcommerce: [
        { text: "Saring E-commerce", value: "default" },
        { text: "Aktif", value: 1 },
        { text: "Tidak Aktif", value: 0 },
      ],
    };
  },

  methods: {
    loopAbjadActive() {
      let obj = this.abjadActive;
      let activeAbjad = "";

      Object.keys(obj).forEach(function (key) {
        if (obj[key] == "btn-info") {
          activeAbjad = activeAbjad + "" + key;
        }
      });

      this.filter.abjad = activeAbjad;
    },

    handleFilterAbjad(data, bool) {
      (this.filter.name = ""), (this.filter.medicine_category_id = "");
      this.filter.medicine_packages_id = "";
      this.filter.EcommerceFilter = "default";
      this.filter.start_date = "";
      this.filter.end_date = "";

      if (data === "a") {
        if (bool === "btn-info") {
          this.abjadActive.a = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.a = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "b") {
        if (bool === "btn-info") {
          this.abjadActive.b = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.b = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "c") {
        if (bool === "btn-info") {
          this.abjadActive.c = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.c = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "d") {
        if (bool === "btn-info") {
          this.abjadActive.d = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.d = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "e") {
        if (bool === "btn-info") {
          this.abjadActive.e = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.e = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "f") {
        if (bool === "btn-info") {
          this.abjadActive.f = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.f = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "g") {
        if (bool === "btn-info") {
          this.abjadActive.g = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.g = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "h") {
        if (bool === "btn-info") {
          this.abjadActive.h = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.h = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "i") {
        if (bool === "btn-info") {
          this.abjadActive.i = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.i = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "j") {
        if (bool === "btn-info") {
          this.abjadActive.j = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.j = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "k") {
        if (bool === "btn-info") {
          this.abjadActive.k = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.k = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "l") {
        if (bool === "btn-info") {
          this.abjadActive.l = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.l = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "m") {
        if (bool === "btn-info") {
          this.abjadActive.m = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.m = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "n") {
        if (bool === "btn-info") {
          this.abjadActive.n = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.n = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "o") {
        if (bool === "btn-info") {
          this.abjadActive.o = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.o = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "p") {
        if (bool === "btn-info") {
          this.abjadActive.p = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.p = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "q") {
        if (bool === "btn-info") {
          this.abjadActive.q = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.q = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "r") {
        if (bool === "btn-info") {
          this.abjadActive.r = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.r = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "s") {
        if (bool === "btn-info") {
          this.abjadActive.s = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.s = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "t") {
        if (bool === "btn-info") {
          this.abjadActive.t = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.t = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "u") {
        if (bool === "btn-info") {
          this.abjadActive.u = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.u = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "v") {
        if (bool === "btn-info") {
          this.abjadActive.v = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.v = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "w") {
        if (bool === "btn-info") {
          this.abjadActive.w = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.w = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "x") {
        if (bool === "btn-info") {
          this.abjadActive.x = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.x = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "y") {
        if (bool === "btn-info") {
          this.abjadActive.y = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.y = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      } else if (data === "z") {
        if (bool === "btn-info") {
          this.abjadActive.z = "btn-outline-info";
          this.loopAbjadActive();
        } else {
          this.abjadActive.z = "btn-info";
          this.filter.abjad = this.filter.abjad + "" + data;
        }
      }

      this.pagination();
    },

    filterAbjad(evt) {
      this.filter = {
        name: "",
        medicine_category_id: "",
        medicine_packages_id: "",
        EcommerceFilter: "default",
        start_date: "",
        end_date: "",
        abjad: "",
      };
      let abdjadCollection = this.abdjadCollection.toString();
      this.filter.abjad = abdjadCollection.replace(/,/g, "");

      this.pagination();
    },

    btnExcelOnClick() {
      
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Daftar Obat dan Alkes.xlsx");
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;
      this.pagination();
    },

    filterName() {
      this.currentPage = 1
      this.abdjadCollection = [];
      this.filter.abjad = "";
      if(this.filter.name == ""){
        this.currentPage = this.lastCurrentPage
      }
      this.pagination()
    },

    resetFilter() {
      this.filterShow = false;
      this.filter.name = ""
      this.packageReset = false
      this.filter.medicine_category_id = ""
      this.filter.medicine_packages_id = ""
      this.filter.EcommerceFilter = "default"
      // this.abdjadCollection = ["A"]
      this.abdjadCollection = []
      this.filter.abjad = "";
      this.pagination();
    },

    async filterByMedicineCategory(evt) {
      if (!evt) {
        this.filter.medicine_category_id = await "";
        this.pagination();
      } else {
        this.filter.medicine_category_id = await evt;
        this.pagination();
      }
    },

    async pagination() {
      // get data
      let filterParams = `&name=${this.filter.name}&abjad=${this.filter.abjad}&medicine_category_id=${this.filter.medicine_category_id}&filterEcommerce=${this.filter.EcommerceFilter}`;
      let response = await module.paginate(
        "medicines",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

        this.items = await response.data;
        this.total_medicine = response.meta.total_medicine;
        this.default_total_medicine = response.meta.total_medicine;
        let pagination = response.meta.pagination;
        this.totalRows = pagination.total;

      let responseNoPaging = await module.get(
        "medicine-no-paginations",
        `?${filterParams}`
      );


      let c, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (c = 0; c < response.data.length; c++) {
        response.data[c].number = b + c;
      }

      this.noPaginations = await responseNoPaging;

      // Coloring
      let a;
      for (a = 0; a < this.items.length; a++) {
        if (
          this.items[a].medicine_remaining_stock <
            this.items[a].minimum_stock &&
          this.items[a].medicine_remaining_stock != 0
        ) {
          this.items[a]._rowVariant = "warning-custom";
        } else if (this.items[a].medicine_remaining_stock == 0) {
          this.items[a]._rowVariant = "danger";
        }
      }

      this.setCheckedItem();
      this.itemProps = this.items;

      this.getBatch();
    },

    setCheckedItem() {
      if (this.medicineItem.length == 0 && this.selectedItem != null) {
        let found;
        this.noPaginations.forEach((value) => {
          found = this.selectedItem.find((x) => x.id == value.id);

          if (typeof found === "undefined") {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
        this.items.forEach((value) => {
          found = this.selectedItem.find((x) => x.id == value.id);

          if (typeof found === "undefined") {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
      }
      
    },

    chooseMedicine(value) {
      this.itemProps.push(value)
      this.$emit("chooseMedicine", value);
    },

    chooseMedicineOrder(value) {
      this.itemProps.push(value)
      this.$emit("chooseMedicineOrder", value);
    },

    async packageMedicine(evt) {
      // this.abdjadCollection = ['a'];
      // this.filter.abjad = "";

      if (this.packageReset == false) {
        // this.items = [];
        // await this.pagination();
        this.packageReset = true;
      }

      this.itemProps = this.items;

      let idData = JSON.stringify(evt);
      let filterParams = `?idData=${idData}`;

      if (
        idData !== "" &&
        idData != [] &&
        idData !== "[]" &&
        typeof idData !== "undefined"
      ) {
        let response = await module.get(
          "medicine-package-data",
          `${filterParams}`
        );

        let arrData = [];
        response.forEach((el) => {
          el.display_medicine.forEach((val) => {
            arrData.push(val);
          });
        });

        this.medicineItem = arrData;
        let datas = arrData;

        let found, a;

        for (a = 0; a < this.noPaginations.length; a++) {
          found = this.medicineItem.find(
            (x) => x.id == this.noPaginations[a].id
          );
          if (typeof found === "undefined") {
            this.noPaginations[a].checked = false;
          } else {
            this.noPaginations[a].checked = true;
          }
        }

        let dataSelected = [];
        let dataUnselected = [];
        let dataResult = [];
        // this.items.forEach( (data,index) => {
        this.noPaginations.forEach((data, index) => {
          if (data.checked == true) {
            dataSelected.push(data);
          } else {
            dataUnselected.push(data);
          }
        });

        dataSelected.forEach((selected) => {
          dataResult.push(selected);
        });

        this.items.forEach((val) => {
          let found = this.medicineItem.find((x) => x.id == val.id);
          if (typeof found === "undefined") {
            dataResult.push(val);
          }
        });
        this.itemProps.push(dataResult);

        localStorage.setItem("dataSelectedPackage", JSON.stringify(dataResult));

        this.$emit("packageMedicine", datas);
        this.$emit("packageMedicineOrder", datas);
      }
    },

    // disini
    async updateMedicine(data) {

      this.abdjadCollection = [];
      this.filter.abjad = "";

      // this.itemProps = this.items;

      // let idData = JSON.stringify(evt)
      // let filterParams = `?idData=${idData}`;
      // let response = await module.get("medicine-package-data",`${filterParams}`);

      let arrData = [];
      data.forEach((el) => {
        // el.display_medicine.forEach(val => {
        arrData.push(el);
        // });
      });

      // this.medicineItem = response.display_medicine;
      // let datas = response.display_medicine;

      this.medicineItem = arrData;
      let datas = arrData;

      let found, a;

      for (a = 0; a < this.noPaginations.length; a++) {
        found = this.medicineItem.find((x) => x.id == this.noPaginations[a].id);
        if (typeof found === "undefined") {
          this.noPaginations[a].checked = false;
        } else {
          this.noPaginations[a].checked = true;
        }
      }

      let dataSelected = [];
      let dataUnselected = [];
      let dataResult = [];
      // this.items.forEach( (data,index) => {
      this.noPaginations.forEach((data, index) => {
        if (data.checked == true) {
          dataSelected.push(data);
        } else {
          dataUnselected.push(data);
        }
      });

      dataSelected.forEach((selected) => {
        dataResult.push(selected);
      });

      this.items.forEach((val) => {
        let found = this.medicineItem.find((x) => x.id == val.id);
        if (typeof found === "undefined") {
          dataResult.push(val);
        }
      });
      this.itemProps = dataResult;

      localStorage.setItem("dataSelectedPackage", JSON.stringify(dataResult));

      this.$emit("packageMedicine", datas);
      this.$emit("packageMedicineOrder", datas);
    },

    async setActive(id) {
      let datas = await module.get("medicines/" + id);

      this.formActive.id = id;
      this.formActive["_method"] = "PUT";

      if (datas["weight"] == 0) {
        Swal.fire({
          icon: "warning",
          title:
            "Berat Barang Tidak Boleh Kosong, Berat Barang Digunakan Untuk Menghitung Ongkos Kirim E-Commerce",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // api.setHeader();
        // let result = await api.put("medicines/" + id + "/set-active");

        // if (result) {

        //   this.pagination();
        // }

        // Make Request
        let response = await module.submit(
          this.formActive,
          "medicines/" + id + "/set-active"
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire(
            "Berhasil",
            "Berhasil Mengubah status E-commerce Obat/Alkes",
            "success"
          );
          this.pagination();
        }
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/purchase-orders-pharmacy");
      }
    },

    async getMedicineCategoryOption() {
      let response = await module.setTreeSelect("medicine-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.medicine_categories = response.data;
        this.medicine_categories.unshift({
          label: "Saring Berdasar Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getBatch() {
      // Batch
      let responseBatch = await module.get(
        "medicine-batch-stock-no-paginations"
      );
      let g;

      this.items.forEach((val, index) => {
        let batch = "<ul>";
        let find = responseBatch.filter((x) => x.medicine_id === val.id);
        if (find.length > 0) {
          find.forEach((val) => {
            if (val.saldo > 0) {
              if (batch == "<ul>") {
                batch = "<li>" + val.batch_no + "</li>";
              } else {
                batch = batch + "<li>" + val.batch_no + "</li>";
              }
              batch = batch + "</ul>";
            }
          });
        }

        this.items[index].batch = batch;

        if (index == this.items.length - 1) {
          this.loadTable = true;
        }
      });

      this.excelData.forEach((val, index) => {
        let batch = "";
        let find = responseBatch.filter((x) => x.medicine_id === val.id);
        if (find.length > 0) {
          find.forEach((val) => {
            if (val.saldo > 0) {
              if (batch == "") {
                batch = val.batch_no;
              } else {
                batch = batch + ", " + val.batch_no;
              }
            }
          });
        }

        this.excelData[index].batch = batch;

        if (index == this.excelData.length - 1) {
          this.loadTable = true;
        }
      });

      this.noPaginations.forEach((val, index) => {
        let batch = "<ul>";
        let find = responseBatch.filter((x) => x.medicine_id === val.id);
        if (find.length > 0) {
          find.forEach((val) => {
            if (val.saldo > 0) {
              if (batch == "<ul>") {
                batch = "<li>" + val.batch_no + "</li>";
              } else {
                batch = batch + "<li>" + val.batch_no + "</li>";
              }
              batch = batch + "</ul>";
            }
          });
        }

        this.noPaginations[index].batch = batch;

        if (index == this.noPaginations.length - 1) {
          this.loadTable = true;
        }
      });
    },

    async getMedicinePackageOption() {
      let response = await module.setTreeSelect("medicine-packages");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoadPackage = true;
        this.medicine_packages = response.data;
        this.medicine_packages.unshift({
          label: "Shortcut Obat dan Alkes",
          id: "null",
          isDisabled: true,
        });
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("medicines/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btn = true;
        }
      }
    },

// disni
    async btnExportOnClick() {
      SwalLoading.fire();

      let filterParams = `&name=${this.filter.name}&abjad=${this.filter.abjad}&medicine_category_id=${this.filter.medicine_category_id}&filterEcommerce=${this.filter.EcommerceFilter}`;

      let responseDatas = await module.paginate(
        "medicines-excel",
        // `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let responseNoPaging = await module.get(
        "medicine-no-paginations",
        `?${filterParams}`
      );

      if(responseDatas){
        Swal.close();
      }

      this.excelData = await responseDatas.data;
      this.noPaginations = await responseNoPaging;
      this.btnExcelOnClick()
      // this.$bvModal.show("modal-medicine-report");

    },
  },

  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem();
    },
    "filter.medicine_package_id": function (newVal) {
      this.packageMedicine(newVal);
    },
  },

  mounted() {
    this.$root.$on("updateMedicine", (data) => {
      this.updateMedicine(data);
    });

    if (this.isAbjad == false) {
      this.filter.abjad = "";
      this.abdjadCollection = [];
    }

    this.pagination();
    this.getMedicineCategoryOption();
    this.getMedicinePackageOption();
    this.setActiveButton();
  },
};
</script>

<style>
.th-list-medicines {
  vertical-align: middle !important;
}

.td-list-medicines {
  vertical-align: middle !important;
}

.nowrap {
  white-space: nowrap;
}
</style>