<template>
  <div>

    <b-modal
      id="modal-medicine-report"
      size="xl"
      title="Expor Daftar Obat dan Alkes"
      style="max-width: 30px; overflow: scroll;"
      hide-footer
    >
      <Table 
        :dataModal="dataModal"
        :excel="excel"
      />
    </b-modal>

  </div>
</template>

<script>

import Table from '@/component/medicine/TableReport.vue'

export default {

  props: {
   dataModal:Array,
   excel:Array,
  },

  components: {
    Table
  },

  methods: {

  //   chosenMedicine(value) {
  //     this.$emit('chosenMedicine', value)
  //   },
    
  //  packageMedicine(datas) {
  //     this.$emit("packageMedicine", datas);      
  //   },


  }

}
</script>

<style>
</style>